import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from '@reach/router';

const JobEdit = () => {
  
  const [job, setJob] = useState({
    id:'',
    image: '', 
    title: '', 
    description: '', 
    salary: '',
    category: '',
    job_type: '',
    location: '',
    skills: '',
  });

  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setJob({ ...job, [name]: value });
  };

 
  return (
    <form >
      <div>
        <label>Image:</label>
        <input type="text" name="image" value={job.image} onChange={handleInputChange} />
      </div>
      <div>
        <label>Title:</label>
        <input type="text" name="title" value={job.title} onChange={handleInputChange} />
      </div>
      <div>
        <label>Description:</label>
        <textarea name="description" value={job.description} onChange={handleInputChange}></textarea>
      </div>
      <div>
        <label>Salary:</label>
        <input type="text" name="salary" value={job.salary} onChange={handleInputChange} />
      </div>
      <div>
        <label>Category:</label>
        <input type="text" name="category" value={job.category} onChange={handleInputChange} />
      </div>
      <div>
        <label>Job Type:</label>
        <input type="text" name="job_type" value={job.job_type} onChange={handleInputChange} />
      </div>
      <div>
        <label>Location:</label>
        <input type="text" name="location" value={job.location} onChange={handleInputChange} />
      </div>
      <div>
        <label>Skills:</label>
        <input type="text" name="skills" value={job.skills} onChange={handleInputChange} />
      </div>
      <button type="submit">Update Job</button>
    </form>
  );
};

export default JobEdit;
